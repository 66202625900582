/* import store from "@/store"; */
import locales from "@/locales";
import store from "@/store";

const lang = store.getters.lang;
const locale = locales[lang];

export const headerClientesTable = [
  {
    text: "Empresa",
    align: "start",
    sortable: true,
    value: "name",
    fixed: true,
    show: true,
    description: "Nome e domínio do cliente",
    width: 300,
  },
  {
    text: "CNPJ",
    value: "cnpj",
    sortable: true,
    show: true,
    description: "CNPJ da empresa",
    width: 160,
  },
  {
    text: "Licenças",
    value: "users_number",
    align: "end",
    sortable: true,
    show: true,
    description: "Número de usuários ativos que o cliente possui",
    width: 100,
  },
  {
    text: "Plano atual",
    value: "plan",
    sortable: false,
    show: true,
    description: "Plano utilizado por este cliente",
    minWidth: 150,
  },
  {
    text: "Plano de pagamento",
    value: "current_plan.subscription_type",
    align: "end",
    sortable: false,
    show: true,
    description: "Plano de pagamento selecionado pelo cliente",
    width: 100,
  },
  {
    text: "Data de criação",
    value: "created_at",
    sortable: true,
    show: true,
    description: "A data de criação no sistema",
    width: 150,
  },
  {
    text: "Tags",
    value: "tag",
    sortable: false,
    show: true,
    description: "Tags atribuídas",
  },
  {
    text: "Próxima cobrança",
    value: "renovation",
    sortable: false,
    show: false,
    description: "Data de renovação do plano do cliente",
    width: 150,
  },
  {
    text: "Responsável pela empresa",
    value: "responsible_email",
    sortable: true,
    show: false,
    description:
      "Nome e e-mail do administrador do Google responsável pela empresa",
  },
  {
    text: "Contrato",
    value: "contract",
    sortable: false,
    show: false,
    description: "Status do contrato (termos)",
  },
  {
    text: "Última visualização",
    value: "last_seen",
    sortable: true,
    show: false,
    description: "Data do ultimo acesso de algum administrador do cliente",
  },
  {
    text: "Parceiro atual",
    value: "reseller_company",
    sortable: false,
    show: false,
    description: "Dados do parceiro atual deste cliente",
  },
  {
    text: "E-mail do financeiro",
    value: "financial_email",
    sortable: true,
    show: false,
    description: "E-mail do setor financeiro",
  },
  {
    text: "Data de cancelamento",
    value: "cancellation_date",
    sortable: true,
    show: false,
    description: "Data em que o cliente foi cancelado",
  },
  {
    text: "Domínios",
    value: "domains_list",
    sortable: false,
    show: false,
    description: "Lista de domínio principal e domínios secundários",
  },
  {
    text: "",
    value: "data-table-expand",
    align: "end",
    sortable: false,
    show: true,
  },
];

export const usersTableHeader = [
  {
    text: locale.common.name,
    align: "start",
    sortable: true,
    value: "name",
    fixed: true,
    show: true,
    description: locale.common.name,
    filterable: true,
    width: 230,
  },
  {
    text: locale.companyProfile.email,
    value: "email",
    sortable: true,
    fixed: true,
    show: true,
    description: locale.companyProfile.email,
    width: 160,
  },
  {
    text: locale.userInformations.departament,
    value: "departament",
    sortable: true,
    show: true,
    width: 200,
    description: locale.userInformations.departament,
  },
  {
    text: locale.tableHeaders.lastLogin,
    value: "last_login",
    sortable: false,
    show: true,
    description: locale.tableHeaders.lastLogin,
    minWidth: 150,
  },
  {
    text: locale.common.organizationUnit,
    value: "ou",
    sortable: false,
    show: false,
    description: locale.common.organizationUnit,
  },
  {
    text: locale.common.googleAdmin,
    value: "is_google_admin",
    sortable: false,
    show: false,
    description: locale.common.googleAdmin,
  },
  {
    text: locale.common.emailSignature,
    value: "applied_signature_key",
    sortable: false,
    show: false,
    description: locale.common.lastSignatureApplied,
  },
  {
    text: locale.sidebar.vacationMessages,
    value: "applied_vacation_key",
    sortable: false,
    show: false,
    description: locale.filters.vacationMessageTemplateAppliedDescription,
    width: 150,
  },
  {
    text: locale.userInformations.workPhone,
    value: "phone",
    sortable: false,
    show: false,
    description: locale.userInformations.workPhone,
  },
  {
    text: locale.userInformations.extension,
    value: "ramal",
    sortable: false,
    show: false,
    description: locale.userInformations.extension,
    width: 150,
  },
  {
    text: locale.userInformations.mobilePhone,
    value: "mobile",
    sortable: false,
    show: false,
    description: locale.userInformations.mobilePhone,
  },
  {
    text: locale.userInformations.office,
    value: "position",
    sortable: false,
    show: false,
    description: locale.userInformations.office,
  },
  {
    text: locale.userInformations.whatsapp,
    value: "whatsapp_number",
    sortable: false,
    show: false,
    description: locale.userInformations.whatsapp,
  },
  {
    text: locale.common.gmailUsage,
    value: "is_gmail_enabled",
    sortable: false,
    show: false,
    minWidth: 150,
    description: locale.common.gmailUsageDescription,
  },
  { text: "", value: "actions", show: true, sortable: false, width: 18 },
];

export const sendasTableHeaders = [
  { text: locale.common.user, value: "user.name", sortable: true },
  {
    text: locale.companyProfile.email,
    align: "start",
    value: "email",
    width: 340,
    sortable: true,
  },
  { text: locale.common.associatedName, value: "name", sortable: true },
  {
    text: locale.common.emailSignature,
    value: "applied_signature_key",
    width: 300,
    sortable: true,
  },
  {
    text: locale.common.type,
    value: "is_primary_email",
    sortable: true,
    width: 110,
  },
  { text: "", value: "actions", sortable: false, width: 20 },
];

export const planOrder = {
  plan_key: "",
  company_key: "",
  status: true,
  subscription_type: "",
  licenses: "",
  max_licenses: "",
  discount: "",
  end_trial: null,
  init_billing: null,
  init_transaction: null,
  end_transaction: null,
  create_transaction: true,
  notes: "",
  renewal_date: null,
};
